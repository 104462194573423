<template>
  <AuthComp
    header="Welcome to Digital Classroom"
    description="Login and let's learn to reach a bright future with us."
  >
    <InputLogin />
  </AuthComp>
</template>

<script>
const InputLogin = () => import('@/components/app/InputLogin')
const AuthComp = () => import('@/components/app/AuthComp/New')
export default {
  components: {
    AuthComp,
    InputLogin,
  },
}
</script>

<style>
</style>
